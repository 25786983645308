/**
 * LM-LIFF-01-1 綁定流程-新門號-OTP驗證
 * https://lmliff.cht.com.tw/signed/new-number/validate
 */
import { useMemo } from 'react';

import ModuleValidation from '@/modules/ModuleValidation';
import { PropTypes } from '@/utils/propTypes';

const NewNumberValidatePage = ({ location }) => {
  const { mobile } = useMemo(
    () =>
      (typeof window !== 'undefined' && location.state) ?? {
        mobile: '',
      },

    [location]
  );

  return (
    <ModuleValidation currentStep={2} linkFormData={{ mobile: mobile || '' }} />
  );
};

NewNumberValidatePage.propTypes = {
  location: PropTypes.object,
};
export default NewNumberValidatePage;
